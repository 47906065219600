import React, { Component, useEffect } from "react";
import "./intro-page.css";
import { fetchOrder, fetchResume } from "../../components/resume/common/fetchResume";
import { useLocation } from "react-router-dom";

export const BMC = () => {
  useEffect(() => {
    document.querySelector(".bmc")?.addEventListener("click", () => {
      document.getElementById("bmc-wbtn")?.click();
    });
  });

  return (
    <div className="bmc-container" key="bmc">
      <div className="bmc">
        <img src="https://cdn.buymeacoffee.com/widget/assets/coffee%20cup.svg" alt="Buy Me A Coffee" />
      </div>
      Enjoying Cvtalize? You can now buy me a coffee.
    </div>
  );
};

export const Header = () => {
  let location = useLocation();

  useEffect(() => {
    console.log("running effect", location.pathname);
    if (location.pathname.indexOf("edit") > -1) {
      document.querySelector("body")?.classList.add("edit");
    } else {
      document.querySelector("body")?.classList.remove("edit");
    }
  }, [location]);

  return (
    <div id="header" style={{ marginBottom: "2em" }}>
      <div>
        <a href="/">
          <img alt="cvtalize logo" className="logo" src="../cvtalize-logo-2.0-light.png"></img>
        </a>
      </div>
      <div className="header-right">
        {window.location.pathname !== "/" && (
          <div>
            <a className="home" href="/">Home</a>
          </div>
        )}
        <div>
            <a href="/edit">My CV</a>
          </div>
        {/* <div>
          <a href="/blog">Blog</a>
        </div> */}
        {/* <div>Resume Tips</div> */}
        {/* <div>FAQ</div> */}
      </div>
    </div>
  );
};

export class Motto extends Component {
  render() {
    return (
      <div id="motto" style={{}}>
        {/* <h1 className="cvtalize-header cvtalize-header--large">Beautiful Resumes, done in seconds</h1> */}
        <h3 className="cvtalize-header cvtalize-header--large">Revitalize your Resume</h3>
        <h3 className="cvtalize-header">
          The ultimate experience to create simple, nice looking and attractive resumes
        </h3>
      </div>
    );
  }
}

export class IntroPage extends Component<{}, {}> {
  resume = fetchResume();
  order = fetchOrder();

  render() {
    return (
      <div>
        <Header></Header>
        <Motto></Motto>
        <div id="res">
          {/* <Resume isPdf={false} isSorting={false} resume={this.resume} order={this.order} template={Cvtalize}></Resume> */}
          {/* <Resume isPdf={false} isSorting={false} resume={this.resume} order={this.order} template={Cvtalize}></Resume> */}
          {/* <Resume isPdf={false} isSorting={false} resume={this.resume} order={this.order} template={Cvtalize}></Resume> */}
        </div>

        <div id="get-started">
          <div className="intro-highlight">
            <a href="/edit" className="get-started-button" style={{ fontSize: "18px" }}>
              Start your Resume
            </a>
          </div>

          <div id="benefits" style={{ backgroundColor: "#061210" }}>
            <div>
              {/* https://www.flaticon.com/free-icon/tap_1612636?term=click&page=1&position=13&origin=tag&related_id=1612636 */}
              <img alt="Click anywhere" src="../tap.png"></img>
              <h3>Click to Edit Anywhere</h3>
              <p>
                Say goodbye to the hassle of filling out forms and wondering how your content will appear on your
                resume. Click anywhere to edit and preview in an instant.{" "}
              </p>
            </div>
            <div>
              <img alt="Resume does not require Sign Up icon" src="../user.png"></img>

              <h3>No Sign Up</h3>
              <p>
                Get started for free, with no sign up required. Upgrade at your convenience to access advanced features.{" "}
              </p>
            </div>
            <div>
              {/* https://www.flaticon.com/free-icon/devices_7213503?term=laptop&page=1&position=79&origin=search&related_id=7213503 */}
              <img alt="Resume stays local on your device" src="../devices.png"></img>

              <h3>Your Data Stays Local</h3>
              <p>
                All your resume data is stored on your local device and there only. Edit with peace of mind, knowing you
                are fully in control of your resume.{" "}
              </p>
            </div>
            <div>
              {/* https://www.flaticon.com/free-icon/pdf_201153?related_id=201153 */}
              <img alt="Resume can be downloaded in PDF icon" src="../pdf.png"></img>

              <h3>Live PDF Preview</h3>
              <p>
                Get a preview of how your PDF resume looks like as you type. Avoid unexpected layout issues and get a
                big picture view of your progress.{" "}
              </p>
            </div>
            <div>
              <img alt="Resume can use templates" src="../template.png"></img>

              <h3>Beautiful Templates</h3>
              <p>
                Switching templates to match your desired look & feel is a breeze. Opt for the default free selection,
                or upgrade to get unique pro templates.{" "}
              </p>
            </div>
          </div>
        </div>
        <BMC></BMC>
      </div>
    );
  }
}
