import React, { Fragment, useEffect } from "react";
import { createRoot } from "react-dom/client";

import "./index.css";
import * as serviceWorker from "./serviceWorker";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { lazy } from "react";
import { PostHogProvider } from "posthog-js/react";
import { IntroPage } from "./pages/Intro/IntroPage";

const DevPage = lazy(() => import("./pages/DevPage"));
const EditPageLazy = lazy(() => import("./pages/Edit/EditPage"));
const BlogPageLazy = lazy(() => import("./pages/Blog/BlogPage"));
const BlogEntryPageLazy = lazy(() => import("./pages/Blog/BlogEntryPage"));

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  autocapture: true,
  persistence: "memory" as any,
  save_referrer: true,
  ip: false,
  request_batching: true,
  mask_all_text: true,
  capture_performance: true,
  session_recording: {
    maskAllInputs: true,
    maskTextSelector: '[contenteditable]'
  }
};

function RootApp() {
  
  let isTest = !!localStorage.getItem('isTest'); 
  
  useEffect(() => {
    document.querySelector(".bmc")?.addEventListener("click", () => {
      document.getElementById("bmc-wbtn")?.click();
    });
  });

  return (
    <Fragment>
      <PostHogProvider apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY} options={options}>
        <BrowserRouter>
          {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
          <Routes>
            {/* <Route path="/dev" element={<div>
                <Suspense fallback={''}>
                  <DevPage />
                </Suspense>
              </div>}>
            </Route> */}
            <Route path="/" element={<IntroPage></IntroPage>}></Route>
            <Route path="/edit" element={<EditPageLazy></EditPageLazy>}></Route>
            <Route path="/blog" element={<BlogPageLazy></BlogPageLazy>}></Route>
            <Route path="/blog/blog1" element={<BlogEntryPageLazy></BlogEntryPageLazy>}></Route>
          </Routes>
        </BrowserRouter>

        </PostHogProvider>

    </Fragment>
  );
}

createRoot(document.getElementById("root")!).render(<RootApp />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
