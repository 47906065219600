import { IResume, IResumeLocation, IResumeBasics, IResumeWork, IResumeEducation, IResumeSkill, IResumeLanguages, IResumeProject, IResumeInterest, IResumeCertificate } from "./props";
import { v4 as uuidv4 } from 'uuid';

const USER_RESUME_KEY = 'user-resume';
const USER_ORDER_KEY = 'user-order';

const getUserResume = () => {
    const localStorageJson = localStorage.getItem(USER_RESUME_KEY);
    return localStorageJson && JSON.parse(localStorageJson);
}

const getUserOrder = () => {
  const localStorageJson = localStorage.getItem(USER_ORDER_KEY);
  return localStorageJson && JSON.parse(localStorageJson);
}

const fetchDefaultOrder = () => {
  return Object.keys(sectionTitles);
}

export const fetchOrder = (): any[] => {
  const order = getUserOrder() || fetchDefaultOrder();
  // when new sections get added, but are no in user's saved order
  // add them to the end
  const difference = fetchDefaultOrder().filter((x: string) => !order.includes(x));
  return [...order, ...difference]
}

export const fetchResume = (): IResume => {
  const defaultResume = fetchDefaultResume();
  const resume =  getUserResume();

  if(!resume) {
    return defaultResume;
  }

  const enhancedSectionTitles = Object.entries(sectionTitles).reduce((acc, [key, value]) => {
    acc[key] = resume.sectionTitles[key] ?? value;
    return acc;
  }, {} as any)

  const enhancedResume  =  Object.keys(defaultResume).reduce((acc, currKey) => {
    acc[currKey] = resume[currKey] ?? (defaultResume as any)[currKey];
    return acc;
  }, {} as any);

  return {
    ...enhancedResume, 
    sectionTitles: enhancedSectionTitles
  }
}

export const persistResume = (resume: IResume) => {
  localStorage.setItem(USER_RESUME_KEY, JSON.stringify(resume));
}

export const persistOrder = (order: string[]) => {
  localStorage.setItem(USER_ORDER_KEY, JSON.stringify(order));
}

export const fetchDefaultWorkEntry = (): IResumeWork => {
  return {
    company: "Your Company",
    position: "My Position at Company X",
    website: "",
    startDate: "Aug 2012",
    endDate: "Aug 2013",
    keywords: 'Javascript/ ES6, HTML 5, CSS 3, Node JS',
    summary:
      "A brief summary of the position and high level description on the main responsibilities whilst in the role can help set the context for your achievements. ",
    highlights: [
      "This section gives you the opportunity to highlight your qualifications. Most people prefer adding these to the resume in the order of the most recent and relevant first. The date fields are constrained to no format, so feel free to specify them in whichever format you like.",
      "Use the 'add a highlight' link to add new entries related to each specific institution. You can also list the most relevant modules you studied, and tailor this for the specific position you are looking for.",
   ],
    id: uuidv4(),
  }
}

export const fetchDefaultEducationEntry = (): IResumeEducation => {
  return {
    institution: "University of X",
    area: "Area or Location",
    studyType: "Course Name and Honours",
    startDate: "Jun 2011",
    endDate: "Jul 2014",
    gpa: "What is this",
    courses: [
      "This section gives you the opportunity to highlight your qualifications. Most people prefer adding these to the resume in the order of the most recent and relevant first. The date fields are constrained to no format, so feel free to specify them in whichever format you like.",
      ],
    id: uuidv4(),
  };
}

export const fetchDefaultSkillsEntry = (name: string, keywords?: string[]): IResumeSkill => {
  return   {
      name: name ?? 'Technical',
      keywords: keywords ?? ['Javascript/ ES6', 'HTML 5', 'CSS 3', 'Node JS'],
      level: 'Proeficient',
      id: uuidv4(),
  }
}

export const fetchDefaultProjectEntry = (project: string, highlights: any = [], description?: string): IResumeProject => {
  return   {
      name: project,
      company: `https://${project}`,
      highlights: highlights.length? highlights : [ ...highlights],
      description: description ?? 'Project description summary',
      startDate: 'Jun 2021',
      endDate: 'Jun 2023',
      keywords: '',
      id: uuidv4(),
  }
}

export const fetchDefaultLanguangeEntry = (lang: string): IResumeLanguages => {
  return {
    language: lang,
    fluency: 'Proeficient',
    id: uuidv4(),
  };
}

export const fetchDefaultInterestEntry = (interest: string): IResumeInterest => {
  return {
    name: interest,
    keywords: [],
    id: uuidv4()
  }
}

export const fetchDefaultCertificateEntry = (name: string, issuer: string): IResumeCertificate => {
  return {
    name,
    date: 'June 2023',
    issuer, 
    id: uuidv4()
  }
}

export const fetchAddNewDefaultWorkEntry = (): IResumeWork => {
  const entry = fetchDefaultWorkEntry();
  entry.position = '';
  entry.isAddNew = true;
  entry.highlights = [];

  return entry;
}

export const fetchAddNewDefaultEducationEntry = (): IResumeEducation => {
  const entry = fetchDefaultEducationEntry();
  entry.institution = '';
  entry.isAddNew = true;
  entry.courses = [];

  return entry;
}

export const fetchAddNewDefaultSkillsEntry = (): IResumeSkill => {
  const entry = fetchDefaultSkillsEntry('default');
  entry.name = ''
  entry.isAddNew = true;
  entry.keywords = [];

  return entry;
}

export const fetchAddNewDefaultProjectEntry = (): IResumeProject => {
  const entry = fetchDefaultProjectEntry('', []);
  entry.name = ''
  entry.description = '';
  entry.isAddNew = true;
  return entry;
}

export const fetchAddNewDefaultLanguagesEntry = (): IResumeLanguages => {
  const entry = fetchDefaultLanguangeEntry('');
  entry.isAddNew = true;

  return entry;
}

export const fetchAddNewDefaultCertificateEntry = (): IResumeCertificate => {
  const entry = fetchDefaultCertificateEntry('', '');
  entry.isAddNew = true;
  return entry;
}

export const fetchAddNewDefaultInterestEntry = (): IResumeInterest => {
  const entry = fetchDefaultInterestEntry('');
  entry.isAddNew = true;
  return entry;
}

export const fetchDefaultResume = (): IResume => {
  const location: IResumeLocation = {
    address: "London, UK, Postcode",
    postalCode: "E120Q9",
    city: "London",
    countryCode: "UK",
    region: "UK",
    id: uuidv4(),
  };

  const basics: IResumeBasics = {
    name: "John Smith",
    email: "john.smith@gmail.com",
    phone: "+44 0000 293 202",
    location,
    label: 'Web Developer / Full Stack Engineer',
    summary: "Click to edit this summary or leave this field empty to exclude from the exported PDF. Whenever writing a CV, the summary section represents one critical aspect of it. It allows the recruiters to immediately identify the value and core skills you can bring to the organisation and whether you are one of the candidates they are looking for.",
    id: uuidv4(),
  };

  const work: IResumeWork[]= [
    fetchDefaultWorkEntry(),
    fetchDefaultWorkEntry(),
    fetchAddNewDefaultWorkEntry(),
  ];

  const education: IResumeEducation[] = [
    fetchDefaultEducationEntry(),
    fetchAddNewDefaultEducationEntry(),
  ];

  const skills: IResumeSkill[]= [
    fetchDefaultSkillsEntry('Technical', ['Javascript/ ES6', 'HTML 5', 'Node JS']),
    fetchDefaultSkillsEntry('Personal', ['Attention to Detail', 'Analytical', 'Time management']),
    fetchDefaultSkillsEntry('Software', ['Figma, Photoshop', 'MS Office Suite', 'Premier Pro']),
    fetchAddNewDefaultSkillsEntry(),
  ];

  const languages: IResumeLanguages[] = [
    fetchDefaultLanguangeEntry('English'),
    fetchDefaultLanguangeEntry('French'),
    fetchDefaultLanguangeEntry('Italian'),
    fetchAddNewDefaultLanguagesEntry(),
  ];

  const projects: IResumeProject[] = [
    fetchDefaultProjectEntry('cvtalize.com', [], 'Beautiful resumes, done in seconds. Revitalize your CV. Simple, nice looking, attractive resumes. '),
    fetchDefaultProjectEntry('anothertodo.com', [], 'Yet another To Do app, this time supercharged for maximizing your productivity.'),
    // fetchDefaultProjectEntry('Hobbies', ['Tennis, Rugby, Swimming', 'Macro Photography']),
    fetchAddNewDefaultProjectEntry(),
  ]

  const interests: IResumeInterest[] = [
    fetchDefaultInterestEntry('Gardening'),
    fetchDefaultInterestEntry('Music/ Jamming'),
    fetchDefaultInterestEntry('Reading / Writing'),
    fetchAddNewDefaultInterestEntry(),
  ]

  const certificates: IResumeCertificate[] = [
    fetchDefaultCertificateEntry('Project Management Professional', "PM Institute"),
    fetchDefaultCertificateEntry('Certified ScrumMaster (CSM)', "Scrum Alliance"),
    fetchDefaultCertificateEntry('Certified Agile Leadership (CAL)', "Scrum Alliance"),
    fetchAddNewDefaultCertificateEntry(),
  ]

  return {
    basics,
    work,
    education,
    skills,
    languages,
    projects,
    certificates,
    interests,
    sectionTitles,
    sectionDelimiters,
  };
};

const sectionTitles = {
  'summary': 'SUMMARY',
  'work': 'EXPERIENCE',
  'education': 'EDUCATION',
  'projects': 'PROJECTS / LEADERSHIP',
  'skills': 'SKILLS',
  'certificates': 'CERTIFICATES',
  'languages': 'LANGUAGES',
};

const sectionDelimiters = {
  'work': '-',
  'education': '-',
  'projects': '-'
};